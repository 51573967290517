<template>
  <v-app id="app">
    <NoJs />
    <v-main>
      <v-row class="md:h-100vh" no-gutters>
        <v-col cols="12" md="6">
          <div class="registration md:max-h-100vh overflow-y-auto">
            <Logo />
            <div class="mt-4">
              <slot></slot>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="bg">
            <v-sheet class="bg-container" theme="dark">
              <div class="bg-image"></div>
              <div class="bg-text">
                <h1>
                  Hard work pays off.<br />
                  Start selling with Orie.
                </h1>
                <div class="my-4">
                  <div class="caption">With your seller account you can:</div>
                  <v-list
                    color="transparent"
                    max-width="350px"
                    density="compact"
                    class="bg-transparent"
                  >
                    <v-list-item
                      v-for="(benefit, i) in benefits"
                      :key="i"
                      class="caption px-0"
                    >
                      <v-list-item-icon class="mr-2">
                        <v-avatar size="25" color="rgba(255,255,255,0.5)">
                          <v-icon color="green">check</v-icon>
                        </v-avatar>
                      </v-list-item-icon>
                      <v-list-item-content class="font-bold">{{
                        benefit
                      }}</v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </v-sheet>
          </div>
        </v-col>
      </v-row>
    </v-main>
    <!-- <AcceptTerms /> -->
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AcceptTerms from "~/components/account-alerts/AcceptTerms.vue";

export default defineComponent({
  components: { AcceptTerms },
  data: () => ({
    benefits: [
      "Sell products, book your calendar and manage events",
      "Manage multiple sales channels from one dashboard",
      "Manage multiple stores with a single seller account",
      "Invite others to any one of your workspaces",
    ],
  }),
});
</script>

<style lang="scss" scoped>
.registration {
  padding: 40px 15px;
  height: 100%;
  @media only screen and (min-width: 768px) {
    padding: 80px 80px 80px 250px;
  }
}

$gb-padding: 200px;

.bg {
  height: 100%;
  display: none;
  overflow: hidden;

  @media only screen and (min-width: 768px) {
    display: flex;
  }

  background-image: radial-gradient(circle at top right, yellow, #f06d06 50%);

  .bg-container {
    height: calc(100% - #{$gb-padding});
    width: calc(100% - #{$gb-padding});
    margin: auto;
    border-radius: 20px;

    background: rgba(255, 255, 255, 0.2);
    // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    position: relative;
    .bg-text {
      padding: 25px;
      z-index: 5;
      position: relative;
    }
    .bg-image {
      z-index: 1;
      position: absolute;
      bottom: 0;
      right: -100px;
      height: 100%;
      width: 100%;
      background-image: url(/img/hard-work-pays-off.png);
      background-repeat: no-repeat;
      background-position: bottom right;
    }
  }
}
</style>
